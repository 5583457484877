import React, { useState } from "react";

import { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useEvent } from "effector-react/scope";

import { t, Trans } from "@lingui/macro";

import { IconButton } from "@chakra-ui/button";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import { CircularProgress, useDisclosure, useToast } from "@chakra-ui/react";

import { BsThreeDots } from "@react-icons/all-files/bs/BsThreeDots";

import ModalConfirmation from "components/modals/confirmation";
import ModalProcessingDetails from "components/modals/processing-details";
import TooltipWrapper from "components/tooltip";

import {
  useIsContributor,
  useIsReadonly,
  useSharedUser,
} from "models/keycloak/hooks";
import wizardModel from "models/wizard";

import {
  calculateProcessingCost,
  deleteProcessing,
  fetchProcessingAoi,
} from "shared/api/client";
import { APIError } from "shared/lib/errors";
import { useGetProjectById } from "shared/lib/hooks/use-getProjectById";
import { useProjectId } from "shared/lib/hooks/use-project-id";

import {
  Processing,
  ProcessingAoi,
  ProcessingCost,
  ProcessingPayload,
} from "types";

import geojsonValidation from "geojson-validation";

import { ShareMenu } from "./share-menu";

export type CardMenuProps = {
  processing: Processing;
};

const CardMenu: React.VFC<CardMenuProps> = ({ processing }) => {
  const [isFetching, setIsFetching] = useState(false);

  const queryClient = useQueryClient();
  const projectId = useProjectId();
  const project = useGetProjectById(projectId);

  const copyProcessingConfig = useEvent(
    wizardModel.events.copyProcessingConfig
  );

  const setParams = useEvent(wizardModel.events.setParams);

  const modals = {
    details: useDisclosure(),
    delete: useDisclosure(),
    // edit: useDisclosure(),
  };
  const isReadOnly = useIsReadonly();
  const isContributor = useIsContributor();
  const sharedUser = useSharedUser();

  const menuDisclosure = useDisclosure();

  const toast = useToast();

  const fetchAoi = () => fetchProcessingAoi(processing.id);

  const { data: processingAoi = [], isLoading: aoiIsLoading } = useQuery<
    ProcessingAoi[],
    AxiosError
  >(["aoi", processing.id], fetchAoi, {
    enabled: isFetching,
    retry: false,
    onSuccess: () => {
      setIsFetching(false);
      menuDisclosure.onOpen();
    },
    onError: () => {
      setIsFetching(false);
      toast({
        title: t`Error to get aoi`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      menuDisclosure.onClose();
    },
  });

  const handleClick = () => {
    if (menuDisclosure.isOpen) {
      menuDisclosure.onClose();
      return;
    }
    setIsFetching(true);
  };
  const handleClose = () => {
    menuDisclosure.onClose();
  };

  const { mutate: validateJobConfig } = useMutation<
    AxiosResponse<ProcessingCost>,
    APIError,
    ProcessingPayload
  >(calculateProcessingCost, {
    onSuccess: async () => {
      copyProcessingConfig({
        name: processing.name,
        source: {
          ...processing.params,
          // type: processing.params.source_type,
          // url: processing.params.url,
        },
        params: processing.params,
        aoi: processingAoi[0].geometry,
        modelId: processing.workflowDef.id,
        modelOptionIds: processing.blocks
          .filter((item) => item.enabled)
          .map((block) => block.name),
      });
      setParams(processing.params);
      toast({
        title: t`Сonfig copied`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t`Error duplicate processing`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });
  const { mutate: handleDelete, isLoading } = useMutation(deleteProcessing, {
    onSuccess: () => {
      queryClient.invalidateQueries(["processings", processing.projectId]);
      toast({
        title: t`Processing deleted`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: t`Error delete processing`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    },
  });

  const handleErrorDuplicate = () => {
    const { geometry: aoi } = processingAoi[0];
    const isValidAoi = geojsonValidation.isGeometryObject(aoi, false);

    const { data: projectData } = project;
    const { workflowDef, blocks: processingBlocks } = processing;
    const { displayProperties, workflowDefs } = projectData || {};

    const isAllModelsAllowed =
      displayProperties?.models === "all" || displayProperties === null;

    const workflow = isAllModelsAllowed
      ? workflowDefs?.find((w) => w.id === workflowDef.id)
      : workflowDefs?.find((w) =>
          Array.isArray(displayProperties?.models)
            ? displayProperties?.models.includes(w.id) &&
              w.id === workflowDef.id
            : false
        );

    const sameNameWorkflow = workflowDefs?.find(
      (workflow) => workflow.name === workflowDef.name
    );

    if (sameNameWorkflow && !workflow) {
      showErrorToast(
        t`Duplicate Processing Error`,
        `This configuration version is outdated. Please contact us for assistance at help@geoalert.io`
      );
      return true;
    }

    if (!workflow) {
      showErrorToast(
        t`Error duplicate processing`,
        `AI model ${workflowDef.name} does not exist`
      );
      return true;
    }

    if (workflow?.blocks?.length) {
      const enabledBlocks = processingBlocks.filter((block) => block.enabled);
      const allBlocksExist = enabledBlocks.every((block) =>
        workflow?.blocks.some((w) => w.name === block.name && w.optional)
      );

      if (!allBlocksExist) {
        showErrorToast(
          t`Error duplicate processing`,
          `AI Post-processing model(s) does not exist`
        );
        return true;
      }
    }

    if (!isValidAoi) {
      showErrorToast(t`Error duplicate processing`, `AOI is not valid`);
      return true;
    }

    if (
      !processing.params.data_provider &&
      !processing.params.source_type &&
      !processing.params.url
    ) {
      showErrorToast(
        t`Error duplicate processing`,
        `Imagery source does not exist`
      );
      return true;
    }
    return false;
  };

  const showErrorToast = (title: string, description: string) => {
    toast({
      title,
      description,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  return (
    <>
      <Menu isOpen={menuDisclosure.isOpen} onClose={handleClose}>
        <MenuButton
          as={IconButton}
          color="blue.500"
          variant="ghost"
          disabled={isLoading || aoiIsLoading}
          icon={
            isLoading || aoiIsLoading ? (
              <CircularProgress size="0.875rem" capIsRound isIndeterminate />
            ) : (
              <BsThreeDots />
            )
          }
          size="sm"
          aria-label="more..."
          onClick={handleClick}
        />
        <MenuList zIndex={100}>
          {/* <MenuItem fontSize="0.875rem" onClick={modals.edit.onOpen}>
            <Trans>Edit</Trans>
          </MenuItem> */}
          <TooltipWrapper
            label={
              isReadOnly || isContributor
                ? t`This feature is not available under the role ${sharedUser}`
                : ""
            }
          >
            <MenuItem
              fontSize="0.875rem"
              onClick={() => {
                if (handleErrorDuplicate()) return;
                validateJobConfig({
                  name: processing.name,
                  wdId: processing.workflowDef.id,
                  geometry: processingAoi[0].geometry,
                  projectId,
                  blocks: processing.blocks,
                });
              }}
            >
              <Trans>Duplicate</Trans>
            </MenuItem>
          </TooltipWrapper>
          <MenuItem fontSize="0.875rem" onClick={modals.details.onOpen}>
            <Trans>Source details</Trans>
          </MenuItem>
          <TooltipWrapper
            label={
              isReadOnly || isContributor
                ? t`This feature is not available under the role ${sharedUser}`
                : ""
            }
          >
            <MenuItem
              isDisabled={isReadOnly || isContributor}
              fontSize="0.875rem"
              as={isReadOnly || isContributor ? "a" : "div"}
              onClick={modals.delete.onOpen}
            >
              <Trans>Delete</Trans>
            </MenuItem>
          </TooltipWrapper>
          <MenuDivider />
          <ShareMenu processing={processing} />
        </MenuList>
      </Menu>
      <ModalProcessingDetails
        isOpen={modals.details.isOpen}
        onClose={modals.details.onClose}
        isCentered
        processing={processing}
        processingId={processing.id}
        rasterLayer={processing.rasterLayer}
        processingAoi={processingAoi}
      />

      <ModalConfirmation
        isCentered
        isOpen={modals.delete.isOpen}
        onClose={modals.delete.onClose}
        onConfirm={() => {
          handleDelete(processing.id);
          modals.delete.onClose();
        }}
        title={t`Delete processing?`}
        description={t`Are you sure you want to delete this processing?`}
        cancelText={t`Cancel`}
        confirmText={t`Delete`}
      />
      {/* <ModalProcessingEdit
        isOpen={modals.edit.isOpen}
        onClose={() => {
          modals.edit.onClose();
          updateMutation.reset();
        }}
        defaultValues={defaultValues}
        error={updateMutation.error}
        isLoading={updateMutation.isLoading}
        onConfirm={updateMutation.mutate}
      /> */}
    </>
  );
};

export default CardMenu;
